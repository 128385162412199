<template>
  <div
    id="app"
    :class="`${skinClasses} theme-${themeColor} h-100`"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
    <b-modal
      id="modal-api-loading"
      :visible="apiLoading"
      aria-label="Loading..."
      aria-hidden=""
      hide-header
      hide-footer
      hide-backdrop
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="d-flex-center flex-column"
    >
      <b-spinner variant="primary" />
      <div v-if="textLoading">
        {{ textLoading }}
      </div>
    </b-modal>
  </div>
</template>

<script>
/* HOW TO SHOW/HIDE "modal-api-loading"
  this.$store.dispatch('app/setLoading', true) | store.dispatch('app/setLoading', true)
  this.$store.dispatch('app/setLoading', false) | store.dispatch('app/setLoading', false)

  this.$bvModal.show('modal-api-loading')
  this.$bvModal.hide('modal-api-loading')

  this.$root.$emit('bv::show::modal', 'modal-api-loading')
  this.$root.$emit('bv::hide::modal', 'modal-api-loading')
  this.$root.$emit('bv::toggle::modal', 'modal-api-loading')
*/

/* HOW TO trigger showToast / showToastError / showToastSuccess:
  1) by dispatch actions:
    use 1 of 3 action names:
    'app/showToast'
    'app/showToastError'
    'app/showToastSuccess'

  NOTE:
    option API:
      this.$store.dispatch()

    composition-api plugin:
      store.dispatch()

  EXAMPLES:
    store.dispatch('app/showToast', {
      titleText: 'Cập nhật hóa đơn thành công.', // plain text
      variant: 'success',
      // other options
    })

    this.$store.dispatch('app/showToastError', {
      title: 'invoice.msgErrUpdateInvoice', // i18n key
    })

    store.dispatch('app/showToastSuccess', {
      title: 'invoice.msgSuccessUpdateInvoice', // i18n key
    })

  2) by call methods of root Vue instance:
    use 1 of 3 method names:
    toast()
    toastError()
    toastSuccess()

  NOTE:
    option API:
      this.$root.toastSuccess()

    composition-api plugin:
      setup() {
        const root = getCurrentInstance().root.proxy

        function onHandleSomething() {
          // ...
          root.toastSuccess({
            title: 'invoice.msgSuccessUpdateInvoice', // i18n key
          })
          // ...
        }

        return {
          onHandleSomething,
        }
      }

    EXAMPLES:
      this.$root.toastSuccess({
        title: 'invoice.msgSuccessUpdateInvoice', // i18n key
      })

  3) by root emit:
    use 1 of 3 event names:
    'showToast'
    'showToastError'
    'showToastSuccess'

  NOTE:
    option API:
      this.$root.$emit()

    composition-api plugin:
      // in setup(): const root = getCurrentInstance().root.proxy
      root.$emit()

  EXAMPLES:
    this.$root.$emit('toastSuccess', {
      title: 'invoice.msgSuccessUpdateInvoice', // i18n key
    })
*/

import { BModal, BSpinner } from 'bootstrap-vue'
// This will be populated in `beforeCreate` hook
// TODO: consider to remove provideToast
import { computed, watch } from '@vue/composition-api'
import { useCssVar, useWindowSize } from '@vueuse/core'
import isObject from 'lodash/isObject'
import { provideToast, useToast } from 'vue-toastification/composition'
import { mapState } from 'vuex'

import store from '@/store'

import useAppConfig from '@core/app-config/useAppConfig'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { $themeBreakpoints, $themeColors, $themeConfig } from '@themeConfig'

import { optionsToast } from './libs/toastification'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ScrollToTop,
    BModal,
    BSpinner,
  },

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapState('app', ['toastOption', 'toastTrigger']),

    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  watch: {
    toastTrigger() {
      this.showToast(this.toastOption)
    },
  },
  mounted() {
    this.$bus.$on('show-toast', message => {
      this.$bvToast.toast(message, {
        title: 'Thông báo',
        solid: false,
      })
    })
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['info', 'secondary', 'success', 'primary', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    // get agency config
    store.dispatch('app/loadConfig', { hostname: window.location.host })
  },

  created() {
    this.$root.toast = option => {
      this.showToast(option)
    }
    this.$root.toastSuccess = (titleOrObject, content = null) => {
      let t = titleOrObject
      let c = content
      if (isObject(titleOrObject)) {
        t = titleOrObject.title
        c = titleOrObject.content
      }
      this.showToast({
        title: t,
        content: c,
        variant: 'success',
        success: true,
      })
    }
    this.$root.toastError = (titleOrObject, content = null) => {
      let t = titleOrObject
      let c = content
      if (isObject(titleOrObject)) {
        t = titleOrObject.title
        c = titleOrObject.content
      }
      this.showToast({
        title: t,
        content: c,
        variant: 'error',
        success: false,
      })
    }

    this.$root.toastWarning = (titleOrObject, content = null) => {
      let t = titleOrObject
      let c = content
      if (isObject(titleOrObject)) {
        t = titleOrObject.title
        c = titleOrObject.content
      }
      this.showToast({
        title: t,
        content: c,
        variant: 'warning',
        success: false,
      })
    }

    this.$root.$on('toast', this.showToast)
    this.$root.$on('toastSuccess', option => {
      this.showToast({
        ...option,
        success: true,
      })
    })
    this.$root.$on('toastError', option => {
      this.showToast({
        ...option,
        success: false,
      })
    })
    this.$root.$on('toastWarning', option => {
      this.showToast({
        ...option,
        success: false,
      })
    })
  },

  setup() {
    const apiLoading = computed(() => store.getters['app/getLoading'])
    const textLoading = computed(() => store.getters['app/getTextLoading'])
    const { skin, skinClasses, themeColor } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // TODO: consider to remove provideToast
    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast(optionsToast)

    const toast = useToast()

    function showToast(optionParam = {}) {
      const option = {
        success: optionParam.variant === 'success',
        title: optionParam.title || '', // i18n key or plain text
        content: optionParam.content || null, // i18n key or plain text

        // props and default values of props for ToastificationContent
        variant: optionParam.variant || 'default',
        icon: optionParam.icon,
        hideClose: optionParam.hideClose || false,
      }

      let variant
      if (option?.variant === 'warning') {
        variant = 'warning'
      } else if (option?.success === true) {
        variant = 'success'
      } else if (option?.success === false) {
        variant = 'danger'
      } else if (option?.variant) {
        variant = option.variant
      } else {
        variant = 'primary'
      }

      let icon
      if (option?.variant === 'warning') {
        icon = 'AlertCircleIcon'
      } else if (option?.success === true) {
        icon = 'CheckIcon'
      } else if (option?.success === false) {
        icon = 'XIcon'
      } else if (option?.icon) {
        icon = option.icon
      } else {
        icon = null
      }

      let title
      if (option?.title) {
        // NOTE: $te = translate exists => true/false
        title = this.$te(option.title) ? this.$t(option.title) : option.title
      } else {
        title = this.$t('oops')
      }

      let text
      if (option?.content) {
        text = this.$te(option.content) ? this.$t(option.content) : option.content
      } else {
        text = null
      }

      const hideClose = !!option?.hideClose

      toast[option.variant]({
        component: ToastificationContent,
        props: {
          variant,
          icon,
          title,
          text,
          hideClose,
        },
      })
    }

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      apiLoading,
      showToast,
      skinClasses,
      enableScrollToTop,
      themeColor,
      textLoading,
    }
  },
}
</script>

<style lang="scss">
#modal-api-loading___BV_modal_outer_ {
  .modal {
    background-color: rgba(#ccc, 0.2);
    backdrop-filter: blur(4px);
    cursor: progress;
  }

  .modal-content {
    background-color: transparent;
    box-shadow: none;
  }
}
</style>
