import { useLocalStorage, useSessionStorage } from '@vueuse/core'

import { parseJsonToObjectOrText } from '@/@core/utils/utils'

export const getUserData = (returnValue = null) => {
  const userDataInLocalStorage = parseJsonToObjectOrText(useLocalStorage('userData', null)?.value)
  const employeeDataInLocalStorage = parseJsonToObjectOrText(useLocalStorage('employeeData', null)?.value)

  const userDataInSessionStorage = parseJsonToObjectOrText(useSessionStorage('userData', null)?.value)
  const employeeDataInSessionStorage = parseJsonToObjectOrText(useSessionStorage('employeeData', null)?.value)

  let storage
  let userData
  let employeeData
  if (userDataInLocalStorage && employeeDataInLocalStorage) {
    storage = localStorage
    userData = userDataInLocalStorage
    employeeData = employeeDataInLocalStorage
  } else if (userDataInSessionStorage && employeeDataInSessionStorage) {
    storage = sessionStorage
    userData = userDataInSessionStorage
    employeeData = employeeDataInSessionStorage
  }

  if (returnValue) {
    return ({ userData, employeeData, storage })?.[returnValue] ?? null
  }
  return { userData, employeeData, storage }
}

export const isUserLoggedIn = () => {
  const { userData, employeeData } = getUserData()
  return !!(userData && employeeData)
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} user user Data
 */
export const getHomeRouteForLoggedInUser = (user, callbackUrl) => {
  if (callbackUrl) {
    return { path: callbackUrl }
  }
  const userRole = user?.type
  const parentAgencyId = user?.agency?.parentAgency?.id
  const userFloor = !parentAgencyId ? 'F1' : parentAgencyId === process.env.VUE_APP_MAMA_AGENCY_ID ? 'F2' : 'F3'
  if (!userRole || !user) return { name: 'auth-login' }
  if (['ADM', 'OPE', 'BE', 'SE', 'CTV'].includes(userRole)) return { name: 'apps-flightsV2-page' }
  if (['KTT', 'KTV'].includes(userRole)) {
    if (userFloor === 'F1' && userRole === 'KTT') return { name: 'apps-flights-agencyBalance' }
    return { name: 'apps-saleReport' }
  }
  return { name: 'auth-login' }
}
